<template>
  <div>
    <div class="contentBox">
      <div class="title">
        <div class="titleText">
          <span>询价客户：
            <span class="user_msg">
            {{ header.user_name | textFormat}}
            <span v-if="header.phone">{{ "( Phone："+header.phone+")" }}</span>
            </span>
          </span>
          <div class="bar" v-if="header.is_quote!=1" >
            <span class="bar_bg" :class="{ 'is_intime':header.is_quote==0,'is_outtime':header.is_quote==2  }"></span>
            <span class="bar_text">{{ header.is_quote==0?"待报价":"已过期" }}</span>
          </div>
        </div>
        <div class="dingcang" >
          <div class="dingcang " v-if="header.is_quote==0">
            <div class="bg_no">更新日期：{{ header.updated_time| secondFormat("LL")}}</div>
          </div>

          <el-button
            v-if="header.is_quote==0"
            style="margin-left: 20px"
            type="success"
            plain
            size="small"
            @click="to_Inquery"
            :loading="loading"
            >我要报价</el-button
          >
          <div class="bg_no" v-if="header.is_quote==1">报价编号：{{ header.quotation_no }}</div>
        </div>
        <div class="dingcang " v-if="header.is_quote!=0">
          <div class="bg_no">更新日期：{{ header.updated_time| secondFormat("LL")}}</div>
        </div>
      </div>

      <shipMsg :header='header' pageType='share'  v-loading="loading" ></shipMsg>

      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="费用明细" name="first"></el-tab-pane>
      </el-tabs>
      <div class="moneyList" v-loading="loading">
        <div class="unnoMsg" v-if="header.is_dangerous">
          <div >UNNO： <span class="color">{{ header.un_no | textFormat }}</span></div>
          <div >PSN： {{ header.pscn | textFormat }}</div>
          <div >Class： {{ header.classify | textFormat }}</div>
          <div >PG： {{ header.packing_group | textFormat }}</div>
        </div>
        <div class="seaItem">
          <div class="itemTitleBox">
            <div>
               <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/miniProgram/ship2.png" style="width:30px;height:30px;margin-right:10px" alt="">
              <span class="itemTitle">海运费：</span>
              <span class="itemMOney"
                >{{ header.currency }}
                <span class="price" >USD {{ seaTotal }}</span
                ></span
              ><span class="itemTips"></span>
            </div>
          </div>
          <div class="itemContent">
            <div class="tableLine tableTitle">
              <div class="table1"></div>
              <div class="table2">币种</div>
              <div class="table3">计量单位</div>
              <div class="table4">单价</div>
            </div>
            <div

              v-for="(item, index) in header.container"
              :key="index"
            >
              <div  v-if="item.reject==0 && item.number&& item.price!=0"  class="tableLine border">
                <div class="table1">海运费</div>
                <div class="table2">{{ item.code||"USD" }}</div>
                <div class="table3">{{ item.name }}</div>
                <div class="table4 price"> {{ item.price }}</div>
              </div>
            </div>
          </div>
        </div>
        <costComp
          :costList='costList'
          @open_close="open_close"
        ></costComp>
      </div>
      <div class="page_tips">
        <i class="el-icon-warning"></i>
        <span>订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。</span>
      </div>
    </div>
  </div>
</template>
<script>
import shipMsg from "~bac/components/ymp/shipMsg";
import costComp from "~bac/components/ymp/costComp";
export default {
  name: "freightSearchDetailsCFS",
  components:{shipMsg,costComp},
  data() {
    return {
      activeName:"first",
      checkedArr: ["20GP"],
      header: {},
      loading: false,
      dialogVisible: false,
      costList: [],
      value: false,
      volume: 0,
      weight: 1000,
      number: 1,
      seaTotal: 0,
      unno: "",
      user_type: 1,
      unnoMsg: {},
    };
  },
  mounted() {
    this.user_type = this.USER_INFO ? this.USER_INFO.user_type : 1;
    if (this.$route.query.unno) {
      this.unno = this.$route.query.unno;
    }
    this.getHeader();
  },
  computed: {},
  methods: {
    changeShow(item){
        item.open = !item.open
        console.log(this.costList)
        this.$forceUpdate()
    },
    // 我要报价
    to_Inquery(){
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.inquire.offer",
        content: JSON.stringify({
          id: this.$route.query.inquiry_id || ''
        }),
      });
      this.$router.push({
        query: {
            id: this.header.inquiry_id
        },
        path: "/console/myoffer/copy",
      })

    },
    handleClose() {},
    async getHeader() {
      this.loading = true;
      let params = {
        inquiry_id: this.$route.query.inquiry_id,
        user_type: 2
      };
      let result = await Promise.all([
        this.$store.dispatch("baseConsole/particulars", params),
      ]);
      this.loading = false;
      if (result[0].success) {
        let data = result[0].data;

        let now = this.$moment().unix()
        if (data.is_quote == 0 && data.validity_end < now) {
          data.is_quote = 2
        }

        data.week = [];
        data.abs = Math.abs(data.price_lcl_dangerous);
        data.shipping_date_arr = data.shipping_date.split(",");
        data.shipping_date_arr.forEach((ee) => {
          if (ee == 1) {
            data.week.push("周一");
          }
          if (ee == 2) {
            data.week.push("周二");
          }
          if (ee == 3) {
            data.week.push("周三");
          }
          if (ee == 4) {
            data.week.push("周四");
          }
          if (ee == 5) {
            data.week.push("周五");
          }
          if (ee == 6) {
            data.week.push("周六");
          }
          if (ee == 7) {
            data.week.push("周日");
          }
        });
        data.container = JSON.parse(data.container);
        let money = 0;
        data.container.forEach((ee) => {
          if (ee.number) {
            money = money + ee.price*1;
          }
        });
        if(data.container.length){
          data.container[0].reject=data.reject_20gp
          data.container[1].reject=data.reject_40gp
          data.container[2].reject=data.reject_40hc
          // data.container[3].reject=data.reject_45gp
        }

        try {
          this.costList = JSON.parse(data.extend);
        } catch (error) {
          this.costList = []
        }
        if(this.costList){
          this.costList.forEach((ee, index) => {
            ee.canCopy = true;
            ee.open = true;
            if (index > 0) {
              ee.open = false;
            }
          });
        }
        console.log(' this.costList', this.costList);

        this.seaTotal =parseInt(money);
        this.header = data;
        this.effect_cost()
      }
    },
    // 选择箱型===>影响附加费  header.container
    effect_cost(){

      let arr=[]
        for( var dd of this.header.container ){
          if(dd.price!=0&&dd.number==1){
            arr.push(dd.name)
          }
        }
        console.log('aaaaaaa',arr);
        console.log('bbbbbbbbb',this.header.container);
      for(var item of this.costList){
        item.group_total_price={}
        for(var it of item.data_list){

          if(it.unit == 5||it.measure=="GP"||it.measure=="箱"){ //---------------------------------------------箱
            for(var key of arr){
              if(key=='20GP'){
                if(item.group_total_price[it.code]){
                  item.group_total_price[it.code]+=Number(it.GP20)
                }else{
                  item.group_total_price[it.code]=Number(it.GP20)
                }
              }
              if(key=='40GP'){
                if(item.group_total_price[it.code]){
                  item.group_total_price[it.code]+=Number(it.GP40)
                }else{
                  item.group_total_price[it.code]=Number(it.GP40)
                }
              }
              if(key=="40HQ"){
                  if(item.group_total_price[it.code]){
                    item.group_total_price[it.code]+=Number(it.HC40)
                  }else{
                    item.group_total_price[it.code]=Number(it.HC40)
                  }
              }
            }
          }else if(it.code==""){  // 没有币种得不计算

          }else{  // 票
            if(item.group_total_price[it.code]){
              item.group_total_price[it.code]+=Number(it.price)
            }else{
              item.group_total_price[it.code]=Number(it.price)
            }
          }
        }
      }
      this.costList=JSON.parse(JSON.stringify(this.costList))
    },
    handleLogin() {
      this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
    },
    open_close(e){ //打开 关闭
      this.costList[e.key].open=!this.costList[e.key].open
      this.costList=JSON.parse(JSON.stringify(this.costList))
    },
  }
};
</script>

<style scoped lang="less">
.is_outtime{
  background: #C8C8C8
}
.is_intime{
  background: #FF6514
}
.contentBox {
  // padding: 0 30px;
}
/deep/ .el-tabs__item.is-active{
  color: #91BE42;
}
.page_tips{
  margin:26px 0;
  display: flex;
  align-items: center;
  i{
    color: #FAAD14;
    font-size:30px;
    padding-right:9px;
  }
  span{
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
}
.border{
  border-bottom: 1px solid #EBEEF5;
}
.p_remarks{
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #909399;
  line-height: 14px;
}
.dingcang {
  display: flex;
  align-items: center;
  .bg_no{
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #0C263F;
    line-height: 22px;
  }
}
.unnoMsg {
  display: flex;
  align-items: center;
  div{
    margin-right: 40px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #3C5058;
    line-height: 20px;
  }
  .color{
    color: #91BE42;
  }
}
.unno {
  display: flex;
  align-items: center;
}
.mohu {
  color: transparent;
  -moz-user-select: none; //火狐
  -webkit-user-select: none; //webkit浏览器（Chrome，Opera，Safari等）
  -ms-user-select: none; //IE10
  -khtml-user-select: none; //早期浏览器
  user-select: none;
  text-shadow: #000 0 0 8px;
}
.moneyList {
  background: #fff;
  padding: 20px;
  padding-top:9px;
  border: 1px solid #DCDFE6;
  border-top:0;
  .seaItem {
    margin-top:40px;
    margin-bottom: 30px;
    .itemTitleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        font-size: 16px;
        color: #999;
        cursor: pointer;
      }
    }
    .itemTitleBox {
      padding-bottom: 20px;
    }
    .el-icon-ship {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: #ff7727;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
      color: #fff;
      margin-right: 10px;
    }
    .itemTitle {
      font-weight: 900;
      font-size: 18px;
    }
    .itemMOney {
      font-weight: 900;
      font-size: 18px;
    }
    .itemTips {
      font-size: 16px;
      color: #ff7727;
      padding-left: 10px;
    }
    .tableLine {
      display: flex;
      align-items: center;
      padding: 13px 0;
    }
    .tableLine {
      .table1 {
        width: 250px;
      }
      .table2 {
        width: 220px;
      }
      .table3 {
        width: 220px;
      }
      .table4 {
        width: 220px;
      }
      .table5 {
        width: 220px;
      }
      .table6 {
        width: 300px;
        text-align: center;
      }
    }
    .tableTitle {
      background: #fbfcfa;
      border-bottom: 1px solid #ebeef5;
    }
  }
}
.seaMoney {
  background: #fff;
  padding: 20px;
  margin: 20px 0;
  .seaTitle {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: 900;
  }
  .searchBox {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .flex {
      display: flex;
      align-items: center;
    }
  }
}
.chooseItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .itemtitle {
    font-size: 14px;
  }

  .switch {
    height: 50px;
  }
}
.booking {
  background-color: #91be42;
  border-color: #91be42;
}
.content_area {
  background: #f3f6f8;
  min-height: 83vh;
}
.title {
  padding: 0 30px;
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-bottom:8px solid #f2f2f2;
  .titleText {
    display: flex;
    span{
      height: 22px;
      font-size: 16px;
      font-weight: 800;
      color: #0C263F;
      line-height: 22px;
    }
    .user_msg{
      color: #FF7727;
    }
    .bar{
      position: relative;
    }
    .bar_bg{
      display: inline-block;
      width: 80px;
      height: 25px;
      border-radius: 1px;
      padding: 3px 19px;
      padding-right:20px;
      transform: translateX(55px) skewX(-20deg);
      white-space: nowrap;
    }
    .bar_text{
      position: absolute;
      width: 80px;
      top: 3px;
      right: -74px;
      color: #FFFFFF;
      font-size: 24px;
      font-weight: bold;
      line-height: 20px;
      font-size: 14px;
    }

  }
  .booking {
    background-color: #91be42;
    border-color: #91be42;
  }
}
.header {
  background: #fff;
  margin-top: 16px;
  height:200px;
  border-bottom:8px solid #f2f2f2;
  padding: 24px 0;
  .heraderTop {
    display: flex;
    justify-content: center;
    text-align: center;

    .div1,.div3 {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .div2 {
      max-width: 250px;
      margin: 0 20px;
    }
    .portEn {
      color: #91be42;
      font-size: 24px;
      font-weight: 800;
      max-width: 200px;
      margin-right: 4px;
    }
    .portZh {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      font-weight: 400;
      line-height: 33px;
      max-width: 120px;
    }
    .line {
      width: 150px;
      height: 1px;
      background: #cdcdcd;
      margin: 5px auto;
      position: relative;
      overflow: visible;
      .jiantou {
        position: absolute;
        color: #cdcdcd;
        right: -1px;
        top: -10px;
      }
    }
    .trans_port {
      color: #333333;
      font-size: 16px;
    }
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
  }
  .heraderButtom {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
    .heraderButtom_line{
        display: flex;
        justify-content: center;
        .ship_item_msg{
          height: 22px;
          font-size: 14px;
          font-weight: 400;
          color: #3C5058;
          line-height: 22px;
          margin-right:40px;
        }

    }
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
    span {
      color: #298df8;
      margin-left: 4px;
    }
  }
}
.content {
  background: #f3f6f8;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px 20px;
  justify-content: space-between;
  //min-height: 500px;
  .item {
    width: 49%;
    background: #ffffff;
    margin-top: 16px;
    .costTitle {
      font-weight: 800;
      color: #1f292e;
      font-size: 16px;
      padding: 20px;
    }
    .line {
      height: 1px;
      width: 100%;
      background: #dcdfe6;
    }
    .costContent {
      padding: 20px;
      .costItem {
        display: flex;
        justify-content: space-between;
        .costItemTitle {
          color: #1f292e;
        }
        .costItemContent {
          color: #f7a350;
          font-size: 18px;
          font-weight: 800;
          span {
            font-size: 14px;
            color: #1f292e;
            font-weight: 400;
            margin-left: 4px;
          }
        }
        .itRemarks {
          margin-top: 6px;
          color: #999999;
        }
        .code {
          font-size: 12px;
          color: #999999;
        }
      }
      .constItem2 {
        margin-top: 20px;
        color: #f46401;
      }
    }
  }
}
.goLogin {
  text-align: center;
  color: #298df8;
  width: 100%;
  padding: 20px 0;
}
.item2Text {
  padding-left: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  color: #333333;
}
.pageHint {
  margin-top: 10px;
  margin-left: 15px;
  padding: 0px 0 15px 15px;
  color: red;
}
.mohu {
  color: transparent;
  -moz-user-select: none; //火狐
  -webkit-user-select: none; //webkit浏览器（Chrome，Opera，Safari等）
  -ms-user-select: none; //IE10
  -khtml-user-select: none; //早期浏览器
  user-select: none;
  text-shadow: #000 0 0 8px;
}
.nodata{
  height: 200px;
  text-align: center;
  line-height: 200px;
  color: #606266;
}
</style>
